


























import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, Mutation } from 'vuex-class';

@Component({})
export default class App extends Vue {
  @Action('bindStock') bindStock!: Function;
  @Mutation('stopAudio', { namespace: 'AudioModule' }) stopAudio!: Function;
  @Mutation('stopHelloLoop') stopHelloLoop!: Function;

  @Watch('$route')
  onRouteChange() {
    this.stopAudio();
    this.stopHelloLoop();
  }

  deferredPrompt: any = false;

  async mounted() {
    await this.bindStock();
    window.addEventListener('beforeinstallprompt', (e: any) => {
      e.preventDefault();
      this.deferredPrompt = e;
      console.log('installed');
      e.prompt();
    });
  }

  showPWA() {
    this.deferredPrompt.prompt();
  }

  get isMac() {
    return process.platform === 'darwin';
  }
}
