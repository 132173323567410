







































































































































































































import { ItemCreatorData, StockItem } from '@/schema';
import imageToBase64 from '@/utils/imageToBase64';
import numberFormat from '@/utils/numberFormat';
import { Component, Vue } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';

@Component({})
export default class Admin extends Vue {
  @State('isElectron') isElectron!: boolean;
  @State('fcmToken') fcmToken!: string;
  @State('stockList') stockList!: StockItem[];
  @State('imageUploadProgress', { namespace: 'FirebaseModule' }) imageUploadProgress!: number;

  @Action('CREATE_ITEM', { namespace: 'FirebaseModule' }) createItem!: Function;
  @Action('UPDATE_ITEM', { namespace: 'FirebaseModule' }) updateItem!: Function;
  @Action('DUPLICATE_ITEM', { namespace: 'FirebaseModule' }) duplicateItem!: Function;
  @Action('DELETE_ITEM', { namespace: 'FirebaseModule' }) deleteItem!: Function;

  isItemCreatorVisible: boolean = false;
  itemCreatorData: ItemCreatorData = {} as ItemCreatorData;
  isItemCreating: boolean = false;
  imageUploadIdx: number = -1;
  $refs: any;

  mounted() {
    this.resetItemCreator();
  }

  testNotification() {
    if (this.fcmToken) prompt('', this.fcmToken);
    else alert('이 기기에서는 알림을 수신할 수 없습니다.');
  }

  closeApp() {
    window.close();
  }

  get getTotalQuantity() {
    return this.stockList.reduce((prev, next) => prev + Number(next.quantity), 0);
  }

  resetItemCreator() {
    this.isItemCreatorVisible = this.isItemCreating = false;
    this.itemCreatorData = {
      name: '',
      alias: '',
      price: 0,
      quantity: 1,
      image: { name: '', blobURL: '', data: '' },
    };
  }

  numberFormat(number: number) {
    return numberFormat(number);
  }

  async onImageChange(id: string, idx: number) {
    let imageDOM = this.$refs.image[idx]['files'][0];
    let blobURL = URL.createObjectURL(imageDOM);

    let image = {
      name: imageDOM.name,
      blobURL: blobURL,
      data: await imageToBase64(blobURL),
    };

    this.imageUploadIdx = idx;

    let result = await this.updateItem({ key: 'image', id: id, value: image });
    if (result) {
      this.imageUploadIdx = -1;
      this.$store.state.imageUploadProgress = 0;
    }
  }

  async onItemCreatorImageChange() {
    if (this.itemCreatorData.image && this.itemCreatorData.image.blobURL !== '')
      URL.revokeObjectURL(this.itemCreatorData.image.blobURL);

    let imageDOM = this.$refs.itemCreatorImage['files'][0];
    let blobURL = URL.createObjectURL(imageDOM);

    this.itemCreatorData.image = {
      name: imageDOM.name,
      blobURL: blobURL,
      data: await imageToBase64(blobURL),
    };
  }

  async submitItemCreator() {
    this.isItemCreating = true;
    let result = await this.createItem(this.itemCreatorData);
    if (result) this.resetItemCreator();
  }
}
