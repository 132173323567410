

















































































































import { StockItem } from '@/schema';
import numberFormat from '@/utils/numberFormat';
import { Component, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';

@Component({})
export default class Order extends Vue {
  @State('isElectron') isElectron!: boolean;
  @State('stockList') stockList!: StockItem[];

  shoppingCartVisible: boolean = false;
  shoppingCart: StockItem[] = [];

  isCheckoutVisible: boolean = false;

  numberFormat(number: number) {
    return numberFormat(number);
  }

  addItem(item: StockItem) {
    this.shoppingCartVisible = true;

    let prevItem = this.shoppingCart.find(s => s.name == item.name);
    // 이미 장바구니에 있을 시 개수 +1
    if (prevItem) {
      // 남은 재고량 확인 후 증감
      if (this.stockList.find(i => i.name == item.name)!.quantity > prevItem.quantity)
        prevItem.quantity++;
    } else this.shoppingCart.push({ ...item, quantity: 1 });
  }
  increaseItem(item: StockItem) {
    let prevItem = this.shoppingCart.find(i => i.name == item.name);
    if (this.stockList.find(i => i.name == item.name)!.quantity > prevItem!.quantity)
      prevItem!.quantity++;
  }
  decreaseItem(item: StockItem) {
    let prevItem = this.shoppingCart.find(i => i.name == item.name);
    if (prevItem!.quantity-- <= 1) this.removeItem(item);
  }
  removeItem(item: StockItem) {
    let prevItemIdx = this.shoppingCart.findIndex(i => i.name == item.name);
    if (prevItemIdx != -1) this.shoppingCart.splice(prevItemIdx, 1);
  }

  get getTotalPrice(): string {
    return numberFormat(
      this.shoppingCart.reduce(
        (total: number, cartItem: StockItem) => total + cartItem.price * cartItem.quantity,
        0
      )
    );
  }
}
