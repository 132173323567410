import { render, staticRenderFns } from "./STT.vue?vue&type=template&id=c9d58d42&scoped=true&"
import script from "./STT.vue?vue&type=script&lang=ts&"
export * from "./STT.vue?vue&type=script&lang=ts&"
import style0 from "./STT.vue?vue&type=style&index=0&id=c9d58d42&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c9d58d42",
  null
  
)

export default component.exports