



































































import STT from '@/components/util/STT.vue';
import { StockItem } from '@/schema';
import numberFormat from '@/utils/numberFormat';
import { Component, Vue } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';

const script = require('@/lib/script.json');
const koreanNumber = require('@/lib/koreanNumber.json');

@Component({
  components: {
    STT,
  },
})
export default class VoiceOrder extends Vue {
  @State('stockList') stockList!: StockItem[];
  @State('script') script!: string;
  @State('userText') userText!: string;

  @Action('playAudio', { namespace: 'AudioModule' }) playAudio!: Function;
  @Action('playItems') playItems!: Function;
  @Action('TTS') TTS!: Function;

  isRecording: boolean = false;
  isSpeakable: boolean = false;
  isOrderProcess: boolean = false;

  isCheckoutVisible: boolean = false;

  shoppingCart: StockItem[] = []; // 현 주문 상품

  async mounted() {
    window.addEventListener('keydown', this.activatePTT);
    window.addEventListener('keyup', this.deactivatePTT);

    this.$store.state.script = script.earphone_connected;
    await this.playAudio({ isLocal: true, data: 'voiceorder/earphone_connected' });
    await this.playItems();
    this.isOrderProcess = true;
    this.orderProcess();

    // setTimeout(() => {
    // 	this.shoppingCart.push(
    // 		{
    // 			name: "망고",
    // 			price: 4000,
    // 			quantity: 2,
    // 			image: "https://firebasestorage.googleapis.com/v0/b/interactive-kiosk.appspot.com/o/products%2Fmango.jpg?alt=media&token=657b4a4a-0be6-4a45-8104-8659daf86edc",
    // 		},
    // 		{
    // 			name: "자몽",
    // 			price: 3000,
    // 			quantity: 5,
    // 			image: "https://firebasestorage.googleapis.com/v0/b/interactive-kiosk.appspot.com/o/products%2Fgrapefruit.jpg?alt=media&token=8f451da3-be70-4100-b94b-7f8514197087",
    // 		}
    // 	);
    // }, 500);
  }

  numberFormat(number: number) {
    return numberFormat(number);
  }

  get getTotalPrice(): string {
    let total = 0;
    this.shoppingCart.forEach(i => {
      total += i.price * i.quantity;
    });
    return numberFormat(total);
  }

  activatePTT(event: KeyboardEvent) {
    if (event.code !== 'Space' || this.isRecording || !this.isSpeakable || !this.isOrderProcess)
      return;
    this.isRecording = true;
    this.isSpeakable = false;
    this.playAudio({ isLocal: true, data: 'voiceorder/ptt_activate' });
  }

  deactivatePTT(event: KeyboardEvent) {
    if (event.code !== 'Space' || !this.isRecording || !this.isOrderProcess) return;
    setTimeout(() => {
      this.isRecording = this.isSpeakable = false;
    }, 1000);
    this.playAudio({ isLocal: true, data: 'voiceorder/ptt_deactivate' });
  }

  async orderProcess() {
    if (this.isOrderProcess) {
      if (!this.shoppingCart.length) {
        this.$store.state.script = script.ask;
        await this.playAudio({ isLocal: true, data: 'voiceorder/ask' });
      } else {
        this.$store.state.script = script.ask_another;
        await this.playAudio({ isLocal: true, data: 'voiceorder/ask_another' });
      }
      this.isSpeakable = true;
    }
  }

  async parseText(text: string) {
    let unavailableItems: StockItem[] = []; // 주문 불가능한 상품

    this.isSpeakable = false;

    if (text === '완료' || text === '종료' || text === '만료') return this.checkout();

    try {
      let split = text.split(new RegExp('(,| )')).filter(data => data.trim() && data.trim() != ',');
      console.log(split);
      for (let i = 0; i < split.length; i++) {
        let word = split[i];
        let nextWord = split[i + 1];

        let wordMatch = word?.match(
          new RegExp(
            `(${this.stockList
              .map(item => [item.name, item.alias])
              .flat()
              .join('|')})`
          )
        )?.input;

        let stockItem: StockItem | undefined;

        if (wordMatch) {
          // 인덱스 가져오기
          stockItem = this.stockList.find(
            item => item.alias!.indexOf(wordMatch!) != -1 || item.name == wordMatch
          );
          if (!stockItem) throw '메뉴가 존재하지 않습니다.';
        } else throw '메뉴가 존재하지 않습니다.';

        let nextWordMatch = nextWord?.match(
          new RegExp(
            `([1-9]+[0-9]*)|(열|스물|서른|마흔|쉰|예순|일흔|여든|아흔)(하나|둘|셋|다섯|여섯|일곱|여덟|아홉)?|(스무)|(한|하나|두|둘|세|셋|네|넷|다섯|여섯|일곱|여덟|아홉)`
          )
        )?.input;

        let quantity = 1;

        if (nextWordMatch) {
          i++;
          // 갯수 가져오기
          let matchCount = String(nextWordMatch.replace('개', ''));
          if (!isNaN(Number(matchCount))) quantity = Number(matchCount);
          else if (matchCount in koreanNumber) quantity = koreanNumber[matchCount];
        }

        let prevStockItem = this.shoppingCart.find(s => s.name == stockItem?.name);
        if (prevStockItem) {
          if (stockItem!.quantity > prevStockItem.quantity) prevStockItem.quantity++;
          else unavailableItems.push(stockItem);
        } else this.shoppingCart.push({ ...stockItem!, quantity: quantity });
      }

      let clearStr = '장바구니에 담긴 제품';
      if (!this.shoppingCart.length) {
        clearStr += '이 없';
        if (unavailableItems.length)
          clearStr += `으며, 주문 불가능한 제품은 ${unavailableItems
            .map(s => s.name)
            .join(',')}입니다.`;
        else clearStr += '습니다.';
      } else {
        clearStr += `은 ${this.shoppingCart.map(item => item.name).join(',')}`;
        if (unavailableItems.length)
          clearStr += `이며, 주문 불가능한 제품은 ${unavailableItems
            .map(s => s.name)
            .join(',')}입니다.`;
        else clearStr += '입니다.';
      }

      this.$store.state.script = clearStr;
      await this.TTS(clearStr);
    } catch (err) {
      console.error(err);
      this.$store.state.script = script.error;
      await this.playAudio({ isLocal: true, data: 'voiceorder/error' });
    }
    this.orderProcess();
  }

  async checkout() {
    this.isRecording = false;
    this.isSpeakable = false;
    this.isOrderProcess = false;

    this.isCheckoutVisible = true;

    this.$store.state.script = script.checkout_complete;
    await this.playAudio({ isLocal: true, data: 'voiceorder/checkout_complete' });
  }
}
