




























import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, Mutation, State } from 'vuex-class';

@Component({})
export default class Home extends Vue {
  @State('isElectron') isElectron!: boolean;
  @State('earphoneDetection') earphoneDetection!: boolean;

  @Mutation('activateEarphoneDetection') activateEarphoneDetection!: Function;

  @Action('startHelloLoop') startHelloLoop!: Function;
  @Action('playAudio', { namespace: 'AudioModule' }) playAudio!: Function;

  @Action('SEND_NOTIFICATION', { namespace: 'FirebaseModule' }) sendNotification!: Function;

  @Watch('earphoneDetection')
  changeEarphoneDetection() {
    if (!this.earphoneDetection) return;
    this.playAudio({ isLocal: true, data: 'home/detection_activated' });
  }

  mounted() {
    navigator.mediaDevices.addEventListener('devicechange', event => {
      if (this.earphoneDetection)
        try {
          this.$router.replace('/voiceorder');
        } catch (err) {
          console.error(err);
        }
    });

    if (this.earphoneDetection) this.startHelloLoop();
  }

  requestAssistant() {
    alert('관리자에게 도움을 요청했습니다.');
    // this.sendNotification();
  }
}
